<template>
    <base-page v-bind:schemaName="'withdrawal'" v-bind:isHideRemove="true">
        <template v-slot:panel-button="{ entity }">
            <button v-if="entity.statusId === '1bf61851-347c-4d0d-a62e-d488dce9c0da'" 
                type="submit" 
                v-on:click.prevent="confirm(entity)" 
                class="btn btn-sm btn-outline-primary">
                    Підтвердити виплату
            </button>            
            <button v-if="entity.statusId === '1bf61851-347c-4d0d-a62e-d488dce9c0da'" 
                type="submit" 
                v-on:click.prevent="cancel(entity)" 
                class="btn btn-sm btn-outline-danger">
                    {{ $filters.localize('button.cancel')}}
            </button>
        </template>
        <template v-slot:panel-left="{ entity }">
            <div class="mb-3 text-left">
                <label class="form-label">Номер заявки</label>
                <input disabled type="number" class="form-control" v-model="entity.number">
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Статус</label>
                <select disabled class="form-control" v-model="entity.statusId">
                    <option v-for="option in statuses" v-bind:key="option.id" :value="option.id">
                        {{ option.name }}
                    </option>
                </select>
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Час підтвердження виплати</label>
                <input type="datetime-local" class="form-control" v-model="entity.paidTime">   
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Перевірив</label>
                <label disabled class="form-control">{{ entity.modifiedBy?.fullName }}</label>
            </div>
        </template>
        <template v-slot:header="{entity}">
            <div class="pt-2 pl-3 pr-3">
                <div class="row">
                    <div class="col-sm text-left">
                        <label class="form-label">Дроп</label>
                        <select disabled class="form-control" v-model="entity.contactId">
                            <option v-for="option in contacts" v-bind:key="option.id" :value="option.id">
                                {{ option.fullName }}
                            </option>
                        </select>
                    </div>
                    <div class="col-sm text-left">
                        <label class="form-label">Кількість, грн</label>
                        <input disabled type="number" :min="1" class="form-control" v-model="entity.amount">
                    </div>   
                </div>        
                <div class="row pb-3">
                    <div class="col-sm text-left">
                        <label class="form-label">Отримувач</label>
                        <input type="text" class="form-control" v-model="entity.recipient">
                    </div>            
                    <div class="col-sm text-left">
                        <label class="form-label">Реквізити отримувача</label>
                        <input type="text" class="form-control" v-model="entity.recipientCard">
                    </div>
                </div>
            </div>
        </template>
    </base-page>
</template>


<script>
import BasePage from '@/components/Sections/BasePage.vue';
export default {
    name: "WithdrawalPage",
    components:{ BasePage },
    data() {
        return{
            contacts:[],
            statuses:[]
        }
    },
    methods: {
        confirm(entity) {
            this.$store.dispatch("post", 
            { 
                table:"withdrawal", 
                method:"confirm", 
                body: { Id: entity.id } 
            }).then(response => {
                entity.statusId = response.data.data.statusId;
                entity.paidTime = response.data.data.paidTime;
            })
            .catch(error => this.$toast.error(error.response.data.message));
        },
        cancel(entity) {
            this.$store.dispatch("post", 
            { 
                table:"withdrawal", 
                method:"cancel", 
                body: { Id: entity.id } 
            }).then(response => {
                entity.statusId = response.data.data.statusId;
            })
            .catch(error => this.$toast.error(error.response.data.message));
        },
        
        loadLookups() {
            this.$store.dispatch('section', { table: "withdrawalStatus" })
            .then((response) => {
                this.statuses = response.data.data;
            });        
            this.$store.dispatch('section', { table: "contact" })
            .then((response) => {
                this.contacts =  response.data.data;
            });
        }
    },
    mounted() {
        this.loadLookups();
    }
}
</script>