<template>
    <chart
      type="bar"
      id="OrderTopClientChart"
      :categories="categories.map(x => x.contact)"
      :series="series"
    ></chart>
</template>

<script>
import Chart from "@/components/Charts/Chart.vue";
export default {
  name:"OrderTopClientChart",
  components: { Chart },
  computed:{
      categories:{
          get(){
              let grouping = this.groupBy("contactId");
              let keys = Object.keys(grouping)
              .map(x => this.getData(x))
              .sort((a,b) => b?.count?.length-a?.count?.length)
              .filter((x, index) => {
                if(index < 10)
                  return x;
              });
              return keys;
          }
      },
      series:{
        get() {
          let result = [];
          let mounth = [];
          this.categories.forEach(item => {
            result.push(item.count.length);
            mounth.push(item.count.filter(x => {
              let date = new Date(x.createdOn);
              let now = new Date(Date.now());
              return date.getMonth() === now.getMonth() && date.getFullYear() === now.getFullYear(); }).length);                
          });
          return [{ name: "За весь час", data: result }, { name: "За місяць", data: mounth }];
        }
    }
  },
  methods: {
      getData(value){
        let contact = this.data.find(x => x.contactId == value)?.contact.fullName;
        let count = this.groupBy("contactId")[value];
        return { id: value, contact, count };
      },
      groupBy(key) {
        return this.data.reduce((rv,x) => { 
            (rv[x[key]] = rv[x[key]] || [])
            .push(x);
          return rv;
        }, {});
      },
      loadData(){
        this.$store.dispatch('section', { table: "order"})
            .then((response) => {
            this.data = response.data.data;
        });
      }
  },
  mounted(){
      this.loadData();
  },
  data() {
    return {
        status:[],
        data:[]
    };
  },
};
</script>