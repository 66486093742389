<template>
  <vSelect
    :options="addresses"
    v-model="address"
    @search="search"
    :reduce="address => address"
    :required="required"
  />
</template>

<script>
import vSelect from "vue-select";
export default {
  name: "select-address",
  emits: ['update', "required"],
  props: { entity: Object },
  components: { vSelect },
  data() {
    return {
      addresses: []
    };
  },
  computed: {
    address: {
      get() {
        return this.entity.address;
      },
      set(value) {
        this.$emit("update", value);
      }
    }
  },
  methods: {
    async search(name) {
      if (name && name != '' && name != null && name != undefined) {
        const result = await this.$store.dispatch('getAPI', { controller: "address", method: `warehouses/${this.entity.city}/${name}` });
        this.addresses = result.data.data.map(x => this.map(x));
      }
    },
    map(data) {
      return data.description;
    },
  }
};
</script>