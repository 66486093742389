<script>
export default {
  name: "order-generate-invoice-button",
  emits: ['update'],
  props: { entity: Object },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async generateInvoice() {
      try {
        this.loading = true;
        const response = await this.$store.dispatch("postAPI",
          {
            controller: "order",
            method: "generateInvoice",
            body: {
              id: this.entity.id
            }
          });

        this.$emit("update", response.data.data);
        this.$toast.success("ТТН успішно згенеровано");
      }
      catch (error) {
        this.$toast.warning(error.response.data.message);
      }
      finally {
        this.loading = false;
      }

    },
  }
};
</script>

<template>
  <button
    v-if="entity.deliveryServiceId === '1bf61851-347c-4d0d-a62e-d488dce9c0da' && entity.statusId === '3bf61851-347c-4d0d-a62e-d488dce9c0da' && !entity.ttn && entity.city && entity.cityName && entity.address && entity.deliveryServiceId && entity.recipient && entity.recipientPhone"
    type="button"
    class="btn btn-sm btn-outline-success"
    v-on:click="generateInvoice"
  >Генерувати ТТН
  </button>
</template>