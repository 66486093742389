<template>
    <div class="pt-2 pb-2">
        <router-link v-bind:class="index === indexActive ? 'text-warning' : ''" :to="{ name: button.path }" 
        v-for="(button, index) in buttons" v-on:click.prevent="setIndex(index, { name: button.path })" v-bind:key="index" 
            class="d-inline mb-3 btn-sm text-center text-uppercase">
            {{ button.name }}
        </router-link>
        <slot name="link"></slot>
    </div>
</template>

<script>
export default {
    name: "TopNavMenu",
    props:{
        buttons: {
            type: Array,
            required: true
        },
    },
    data(){
        return {
            indexActive: 0,
        }
    },
    methods:{
        setIndex(index, path){
            this.indexActive = index;
            this.$router.push(path);
        }
    }
}
</script>

<style scoped>
a {
  color: white;
}
a.hover {
    color: white;
}
</style>