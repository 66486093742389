<template>
    <div>
        <router-link
          class="text-left btn btn-sm btn-info"
          :to="{ name: 'OrderBotPage', params: { id: uuid } }"
        >Оформити замовлення</router-link>
    </div>
    <form
      class="input-group m-2 pr-4"
      v-if="this.fields.length > 0"
      v-on:submit.prevent="addFilter(filter)"
    >
        <select
          class="form-control"
          v-model="filter.field"
        >
            <option
              v-for="option in fields"
              v-bind:key="option.field"
              :value="option.field"
            >
                {{ option.name }}
            </option>
        </select>
        <select
          class="form-control"
          v-model="filter.type"
        >
            <option
              v-for="option in typesFilterfilter(filter)"
              v-bind:key="option.value"
              :value="option.value"
            >
                {{ option.name }}
            </option>
        </select>
        <input
          v-if="currentInputType(filter) != 'lookup'"
          :type="currentInputType(filter)"
          class="form-control text-center"
          v-model="filter.value"
          required
        >
        <select
          v-else
          class="form-control"
          v-model="filter.value"
          required
        >
            <option
              v-for="option in fields.find(x => x.field == filter.field).lookup"
              v-bind:key="option.id"
              :value="option.id"
            >
                {{ option.name }}
            </option>
        </select>
        <div class="input-group-append">
            <button
              :disabled="!(!!filter.value && !!filter.field && !!filter.type) && currentInputType(filter) != 'checkbox'"
              class="btn btn-outline-secondary"
            >{{ $filters.localize("main.submitFilter") }}</button>
        </div>
    </form>
    <div
      class="m-2 row"
      v-if="filters.length > 0"
    >
        <div
          class="input-group col-sm"
          v-for="(filter, index) in filters"
          v-bind:key="index"
        >
            <label
              class="form-control text-left"
              v-on:click.prevent="removeFilter(index)"
            >
                {{ getFilterString(filter) }}
            </label>
            <select
              class="form-control"
              v-model="filter.type"
            >
                <option
                  v-for="option in typesFilterfilter(filter)"
                  v-bind:key="option.value"
                  :value="option.value"
                >
                    {{ option.name }}
                </option>
            </select>

            <input
              v-if="currentInputType(filter) != 'lookup'"
              :type="currentInputType(filter)"
              class="form-control text-center"
              v-model="filter.value"
              required
            >
            <select
              v-else
              class="form-control"
              v-model="filter.value"
              required
            >
                <option
                  v-for="option in fields.find(x => x.field == filter.field).lookup"
                  v-bind:key="option.id"
                  :value="option.id"
                >
                    {{ option.name }}
                </option>
            </select>
        </div>
    </div>
    <div class="rounded shadow bg-white m-2 mr-3 flow-show">
        <table class="table table-hover rounded shadow">
            <thead>
                <tr class="rounded shadow">
                    <th
                      class="text-center"
                      v-for="header of headers"
                      v-bind:key="header.field"
                    >
                        <p class="d-inline">{{ header.name }} &nbsp;</p>
                    </th>
                    <th class="text-center">
                        <p class="d-inline">ТТН&nbsp;</p>
                    </th>
                    <th class="text-center">
                        <p class="d-inline">Маржа&nbsp;</p>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                  v-for="(row, index) of rowsData"
                  v-bind:key="index"
                >
                    <td
                      class="text-center"
                      v-bind:class="header.class ? header.class : ''"
                      v-for="header of headers"
                      v-bind:key="index + header.field"
                      v-on:click.middle.prevent="routerOpen(row.id, 'OrderBotPage')"
                      v-on:click="router(row.id, 'OrderBotPage')"
                    >
                        {{ getRowValue(row, header) }}
                    </td>
                    <td class="text-center">
                        {{ getRowValue(row, { field: "ttn", name: "ТТН" }) }}
                    </td>
                    <td class="text-center">
                        {{
                            (Number(getRowValue(row, { field: "cashPaidAmount" })) + Number(getRowValue(row, {
                                field: "bonusPaidAmount"
                            }))
                                + Number(getRowValue(row, { field: "paymentAmount" }))) - Number(getRowValue(row, {
                                    field: "totalCost"
                                }))
                        }}
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import rows from '@/mixins/rows.js';
export default {
    name: "OrdersBotPage",
    mixins: [rows],
    computed: {
        rowsData: {
            get() {
                return [...this.rows].sort((a, b) => {
                    let modifier = 1;
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                });
            }
        }
    },
    data() {
        return {
            currentSort: "number",
            currentSortDir: "desc",
            uuid: uuidv4(),
            headers: [
                { field: "number", name: "Номер" },
                { field: "recipient", name: "Клієнт" },
                { field: "status.name", name: "Стан" }],
            rows: [],
            filters: [],
            filter: {
                type: "",
                value: null,
                field: ""
            },
            filterTypes: [
                { name: "Дорівнює", value: "Equal", char: "=", type: "all" },
                { name: "Не дорівнює", value: "NotEqual", char: "<>", type: "all" },
                { name: "Більше", value: "More", char: ">", type: "number, date" },
                { name: "Меньше", value: "Less", char: "<", type: "number, date" },
                { name: "Включає", value: "Contains", char: "включає", type: "string" },
                { name: "Виключає", value: "NotContains", char: "виключає", type: "string" }
            ],
            fields: [
                { field: "number", name: "Номер", type: "number" },
                { field: "recipientPhone", name: "Номер клієнта", type: "string" },
                { field: "recipient", name: "Отримувач", type: "string" }
            ]
        }
    },
    watch: {
        filters: {
            handler() {
                this.loadRows();
            },
            deep: true
        }
    },
    methods:
    {
        loadRows() {
            this.$http.post(window.location.origin + "/api/v1/order/orders", this.filters.map(function (x) { return { Value: x.value, Field: x.field, Operation: x.type }; }))
                .then((response) => {
                    this.rows = response.data.data;
                });
        },
        typesFilterfilter(filter) {
            if (filter.field) {
                var type = this.fields.find(x => x.field == filter.field)?.type ?? "all";
                return this.filterTypes.filter(x => x.type == "all" || x.type.includes(type));
            }
            return [];
        },
        currentInputType(filter) {
            if (filter.field) {
                var type = this.fields.find(x => x.field == filter.field)?.type ?? "all";
                return type == "all" ? "text" : type;
            }
            return "text";
        },
        addFilter(filter) {
            this.filters.push(filter);
            localStorage.setItem(this.schemaName, JSON.stringify(this.filters));
            this.filter = {
                type: "",
                value: null,
                field: ""
            };
            this.loadRows();
        },
        removeFilter(indexFilter) {
            this.filters = this.filters.filter((_, index) => index !== indexFilter);
            localStorage.setItem(this.schemaName, JSON.stringify(this.filters));
            this.loadRows();
        },
        clearFilters() {
            this.filters = [];
            localStorage.removeItem(this.schemaName);
        },
        getFilterString(filter) {
            return this.fields.find(x => x.field == filter.field)?.name
        },
    },
    mounted() {
        this.loadRows();
    }
}
</script>

<style scoped>
.flow-show {
    overflow-y: scroll;
    max-height: calc(100vh - 70px);
}
</style>