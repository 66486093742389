<template>
    <base-page v-bind:schemaName="'paymentdetail'">
        <template v-slot:panel-button></template>
        <template v-slot:panel-left="{ entity }">
            <div class="mb-3 text-left">
                <label class="form-label">Назва</label>
                <input class="form-control" v-model="entity.name">
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Номер</label>                
                <input class="form-control" v-model="entity.number">
            </div>
            <div class="mb-3 text-left">
                <div>
                    <label class="form-label">Активний</label>
                </div>
                <input type="checkbox" v-model="entity.isActive">
            </div>
        </template>
        <template v-slot:header></template>
    </base-page>
</template>


<script>
import BasePage from '@/components/Sections/BasePage.vue';
export default {
    name: "PaymentDetailPage",
    components:{ BasePage }
}
</script>