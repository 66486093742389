import ua from '@/locales/ua.json'
import en from '@/locales/en.json'

const locales = {
    'ua-UA': ua,
    'en-US': en
}

export default function localizeFilter(key) {
    var keys = key.split('.');
    const locale = localStorage.getItem("locale");
    var local = locales[locale] ?? locales['ua-UA'];
    keys.forEach(item => {
        try {
            local = local[item];
        }
        catch {
            local = `[Localize error] ${key} not found`;
        }
    });

    return local;
}