<template>
    <base-page v-bind:schemaName="'contact'">
        <template v-slot:panel-left="{ entity }">
            <div class="mb-3 text-left">
                <label class="form-label">{{ $filters.localize("ContactPage.fields.fullName") }}</label>
                <input
                  :disabled="true"
                  type="text"
                  class="form-control"
                  v-model="entity.fullName"
                >
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">{{ $filters.localize("ContactPage.fields.surname") }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="entity.surname"
                  required
                >
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">{{ $filters.localize("ContactPage.fields.name") }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="entity.name"
                  required
                >
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">{{ $filters.localize("ContactPage.fields.givename") }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="entity.givename"
                >
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">{{ $filters.localize("ContactPage.fields.mobilePhone") }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="entity.mobilePhone"
                  required
                >
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">{{ $filters.localize("ContactPage.fields.code") }}</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="entity.code"
                  required
                >
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">{{ $filters.localize("ContactPage.fields.personalBonus") }}</label>
                <input
                  type="number"
                  class="form-control"
                  v-model="entity.personalBonus"
                  required
                >
            </div>
        </template>

        <template v-slot:header="props">
            <div class="row pt-3">
                <div class="col-sm">
                    <div class="form-group row ml-2 pr-2">
                        <label class="col-sm-2 col-form-label">{{ $filters.localize("ContactPage.fields.type")
                            }}</label>
                        <div class="col-sm">
                            <select
                              :disabled="types.length === 0"
                              class="form-control"
                              v-model="props.entity.typeId"
                            >
                                <option
                                  v-for="option in types"
                                  v-bind:key="option.id"
                                  :value="option.id"
                                >
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-sm">
                    <div class="form-group row ml-2 pr-2">
                        <label class="col-sm-2 col-form-label">{{ $filters.localize("ContactPage.fields.status")
                            }}</label>
                        <div class="col-sm">
                            <select
                              :disabled="statutes.length === 0"
                              class="form-control"
                              v-model="props.entity.statusId"
                            >
                                <option
                                  v-for="option in statutes"
                                  v-bind:key="option.id"
                                  :value="option.id"
                                >
                                    {{ option.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:body="{ action }">
            <sender-detail v-bind:contactId="this.$route.params.id" />
            <transactions-detail
              v-bind:rootId="this.$route.params.id"
              v-bind:contactId="this.$route.params.id"
              v-bind:rootColumn="'ContactId'"
              v-bind:enabled="action == 'update'"
              class="mb-3"
            />
            <contact-communication-detail
              v-bind:rootId="this.$route.params.id"
              v-bind:rootColumn="'ContactId'"
              v-bind:enabled="action == 'update'"
              class="mb-3"
            />
            <message-detail
              v-bind:rootId="this.$route.params.id"
              v-bind:rootColumn="'ContactId'"
              class="mb-3"
            />
            <user-detail
              v-if="this.$store.getters.isAdmin"
              v-bind:rootId="this.$route.params.id"
              v-bind:rootColumn="'ContactId'"
              v-bind:enabled="action == 'update'"
            />
        </template>
    </base-page>
</template>

<script>
import SenderDetail from "@/views/details/messages/SenderDetail.vue";
import UserDetail from "@/views/details/users/UserDetail.vue";
import MessageDetail from "@/views/details/messages/MessageDetail.vue";
import TransactionsDetail from "@/views/details/transactions/TransactionsDetail.vue";
import ContactCommunicationDetail from "@/views/details/contacts/ContactCommunicationDetail.vue";
import BasePage from '@/components/Sections/BasePage.vue';
export default {
    name: "ContactPage",
    components: { BasePage, ContactCommunicationDetail, TransactionsDetail, MessageDetail, UserDetail, SenderDetail },
    data() {
        return {
            statutes: [],
            types: []
        };
    },
    methods: {
        loadLookups() {
            this.$store.dispatch('section', { table: "contactstatus" })
                .then((response) => {
                    this.statutes = response.data.data;
                });
            this.$store.dispatch('section', { table: "contacttype" })
                .then((response) => {
                    this.types = response.data.data;
                });
        }
    },
    mounted() {
        this.loadLookups();
    }
}
</script>