<template>
    <apexchart
      width="30%"
      heigth="10%"
      :type="type"
      :options="chartOptions"
      :series="series"
    ></apexchart>
</template>

<script>
export default {
  name:"Chart",
  computed:{
      chartOptions:{
          get(){
            return {
                chart:{ id : this.id },
                xaxis: {
                    categories: [...this.categories],
                },
            }
        }
      }
  },
  props:{
      type: { type:String, required: true},
      id: { type: String, required: true},
      categories: { type: Array, required: true},
      series: { type: Array, required: true}
  }
};
</script>