<template>
    <t-section v-bind:schemaName="'productCategory'" v-bind:fields="fields" v-bind:headers="headers"></t-section>
</template>

<script>
    import TSection from '@/components/Sections/Section.vue';
    export default {
        name: "ProductCategorySection",
        components:{
            TSection,
        },
        data() {
            return {
                headers:[
                    { field:"name", name:"Название", sort:true, router: { field:"id", name:"ProductCategoryPage" }},
                    { field:"code", name:"Код", sort:true,  router: { field:"id", name:"ProductCategoryPage" } },
                ],
                fields:[
                    { field: "name", name: "Название", type:"string" },
                ],
            }
        }
    }
</script>