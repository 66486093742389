<template>
<t-section v-bind:schemaName="'message'" v-bind:fields="fields" v-bind:headers="headers" v-bind:defaultSort="'desc'" v-bind:isHideChart="true" v-bind:isHideButton="true">
        <template v-slot:custom>
            <sender-detail/>
        </template>
    </t-section>
</template>

<script>
import SenderDetail from "@/views/details/messages/SenderDetail.vue";
    import TSection from '@/components/Sections/SectionV3.vue';
    export default {
        name: "MessageSection",
        components:{
            TSection,
            SenderDetail
        },
        data() {
            return {
                headers:[
                    { field:"createdOn", name: this.$filters.localize('main.createdOn'), sort:true, router: { field:"id", name:"MessagePage" }},
                    { field:"text", name:"Текст", colspan:2, class:'mw-sm', sort:true, router: { field:"id", name:"MessagePage" }},
                    { field:"identifier", name:"Ідентифікатор", sort:true, filter:true, router: { field:"id", name:"MessagePage" }},
                    { field:"contact.fullName", name:"Контакт", sort:true, filter:true, router: { field:"id", name:"MessagePage" }}
                ],
                fields: [
                    { field: "createdOn", name:this.$filters.localize('main.createdOn'), type:"date" },
                    { field: "contact.fullName", name: "Контакт", type:"string" },
                    { field: "text", name: "Текст", type:"string" },
                ]
            }
        }
    }
</script>
