<template>
  <div></div>
</template>

<script>
export default {
    name: "Hangfire",
    mounted(){
        
        if(this.$store.getters.isAdmin)
            window.location = "hangfire";
        else 
            this.$route.push({path:"contact"})
    }
}
</script>