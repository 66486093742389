<template>
  <div>

  </div>
</template>

<script>
export default {
    name:"SignOut",
    mounted(){
        this.$store.dispatch("botSingOut");
        this.$store.dispatch("signOut");
        this.$router.push({ name:"Login" });
    }
}
</script>