<template>
    <div class="container text-center">
        <div
          class="container mt-5 pt-5"
          style="max-width:500px"
        >
            <img src="../assets/logo-b.png" />
            <form @submit.prevent="login">
                <label class="d-flex required">{{ $filters.localize("login.login") }}</label>
                <div class="form-group justify-content-between">
                    <input
                      id="email"
                      v-model="userName"
                      type="text"
                      class="form-control"
                      :placeholder="$filters.localize('login.login')"
                      required
                    />
                </div>
                <label class="d-flex required">{{ $filters.localize("login.password") }}</label>
                <div class="form-group">
                    <input
                      id="password"
                      v-model="userPassword"
                      type="password"
                      class="form-control"
                      :placeholder="$filters.localize('login.password')"
                      required
                    />
                </div>
                <button
                  class="btn btn-outline-primary w-100"
                  type="submit"
                >{{ $filters.localize("login.submit") }}</button>

            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'VLogin',
    data() {
        return {
            userName: "",
            userPassword: ""
        };
    },
    methods: {
        login() {
            this.$store.dispatch("login", { UserName: this.userName, UserPassword: this.userPassword })
                .then(() => this.$router.push({ name: 'Contact' }))
                .catch(error => {
                    this.$toast.error(this.$filters.localize("login.invalid"));
                    console.log(error);
                });
        }
    },
}
</script>
