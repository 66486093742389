import axios from 'axios';
import cookie from 'js-cookie';

export default {
  state: () => ({
    token:null
  }),
  mutations: {
    SET_TOKEN_BOT: (state, token) => {
      state.token = token;
      cookie.set("Authorization", token, { secure: true });
      cookie.set("ImBot", true, { secure: true });
    },

    REMOVE_TOKEN_BOT:(state) =>{
      state.token = null;
      cookie.remove("Authorization");
      cookie.remove("ImBot");
    }
  },
  actions: {
    botLogin({ commit }, token) {
        axios.defaults.headers.common['Authorization'] = token;
        commit("SET_TOKEN_BOT", token);
    },
    botSingOut({ commit }) {
      delete axios.defaults.headers.common['Authorization'];
      commit("REMOVE_TOKEN_BOT");
    }
  },
  modules: {},
  getters: {
    botIsLoggedIn: (state) => {
        return !!state.token && !!cookie.get("Authorization");
    },
    botToken: (state) => {
      var token = state.token;
      if(token == null || !token)  
        token = cookie.get("Authorization");
      return token && token != "" && token != "undefined" ? token : null;  
    },
  },
};
