<template>
    <t-section
      v-bind:schemaName="'contact'"
      v-bind:fields="fields"
      v-bind:headers="headers"
      v-bind:isHideChart="true"
    >
        <template v-slot:custom>
            <sender-detail />
        </template>
    </t-section>
</template>

<script>
import SenderDetail from "@/views/details/messages/SenderDetail.vue";
import TSection from '@/components/Sections/SectionV3.vue';
export default {
    name: "SectionContact",
    components: {
        TSection,
        SenderDetail
    },
    methods: {
        async loadLookups() {
            const response = await this.$store.dispatch('section', { table: "contactStatus", body: {} })
            this.lookups.status = response.data.data ?? [];
        },
    },
    computed: {
        fields: {
            get() {
                return [
                    { field: "statusId", name: "Статус", type: "lookup", lookup: this.lookups.status },
                    { field: "mobilePhone", name: this.$filters.localize("ContactPage.fields.mobilePhone"), type: "string" },
                    { field: "surname", name: this.$filters.localize("ContactPage.fields.fullName"), type: "string" },
                    { field: "code", name: this.$filters.localize("ContactPage.fields.code"), type: "string" }
                ]
            }
        }
    },
    data() {
        return {
            lookups: {
                status: []
            },
            headers: [
                { field: "surname", name: this.$filters.localize("ContactPage.fields.surname"), sort: true, router: { field: "id", name: "ContactPage" } },
                { field: "name", name: this.$filters.localize("ContactPage.fields.name"), sort: true, router: { field: "id", name: "ContactPage" } },
                { field: "mobilePhone", name: this.$filters.localize("ContactPage.fields.mobilePhone"), sort: true, filter: true, router: { field: "id", name: "ContactPage" } },
                { field: "status.name", name: "Статус", sort: true, filter: true, config: { field: "statusId", type: "status" }, router: { field: "id", name: "ContactPage" } }
            ],
        }
    },
    async mounted() {
        await this.loadLookups();
    }
}
</script>