<template>
    <base-page v-bind:schemaName="'promotion'">
        <template v-slot:panel-left="{ entity }">
            <div class="mb-3 text-left">
                <label class="form-label">Назва</label>
                <input type="text" class="form-control" v-model="entity.name">
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Ціна</label>
                <input type="number" class="form-control" v-model="entity.value">
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Тип</label>
                <select :disabled="types.length === 0" class="form-control" v-model="entity.typeId">
                    <option v-for="option in types" v-bind:key="option.id" :value="option.id">
                        {{ option.name }}
                    </option>
                </select>
            </div>
            <div class="mb-3 text-left">
                <div>
                    <label class="form-label">Активний</label>
                </div>
                <input type="checkbox" v-model="entity.isActive">
            </div>
        </template>
    </base-page>
</template>

<script>
import BasePage from '@/components/Sections/BasePage.vue';
export default {
    name: "PromotionPage",
    components:{ BasePage },
    data(){
        return{
            types:[]
        }
    },
    methods:{
        loadLookups() {
            this.$store.dispatch('section', { table: "promotiontype" })
            .then((response) => {
                this.types = response.data.data;
            });
        }
    },
    mounted(){
        this.loadLookups();
    }
}

</script>
<style scoped>
.image-size{
    max-height: 300px;
    width: 300px;
}
</style>