<template>
    <base-page
      v-bind:schemaName="'systemSetting'"
      v-bind:isHideRemove="true"
    >
        <template v-slot:panel-left="{ entity }">
            <div class="mb-3 text-left">
                <label class="form-label">Название</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="entity.name"
                  required
                >
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Код</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="entity.code"
                  required
                >
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Значение</label>
                <selector-city
                  v-if="entity && entity.code === 'NovaPoshtaCitySender'"
                  :entity="city"
                  @update="(value) => { entity.value = value.code; entity.description = value.label; }"
                />
                <selector-address
                  v-else-if="entity && entity.code === 'NovaPoshtaCityDepartmentSender'"
                  :entity="address"
                  @update="(value) => { entity.value = value; }"
                />
                <input
                  :disabled="entity.code === 'NovaPoshtaCityDepartmentSender' || entity.code === 'NovaPoshtaCitySender'"
                  class="form-control mt-1"
                  v-model="entity.value"
                  required
                >
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Описание</label>
                <input
                  class="form-control"
                  v-model="entity.description"
                >
            </div>
        </template>

        <template v-slot:header>
        </template>
    </base-page>
</template>


<script>
import BasePage from '@/components/Sections/BasePage.vue';
import SelectorCity from '@/components/Selectors/SelectorCity.vue';
import SelectorAddress from '@/components/Selectors/SelectorAddress.vue';
export default {
    name: "SystemSettingPage",
    components: { BasePage, SelectorCity, SelectorAddress },
    data() {
        return {
            citySettingId: "25ab08d9-9eed-4323-b390-bba2cdfd7410",
            cityId: '',

        };
    },
    computed: {
        city: {
            get() {
                return { city: this.entity?.value, cityName: this.entity?.description };
            },
            set(value) {
                this.entity.value = value.city;
                this.entity.description = value.cityName;
            }
        },
        address: {
            get() {
                return { address: this.entity?.value, city: this.cityId };
            },
            set(value) {
                this.entity.value = value;
            }
        },
    },

    async mounted() {
        const response = await this.$store.dispatch('index', { table: "SystemSetting", body: { id: this.citySettingId } });
        var result = response.data;
        this.cityId = result.data.value;
    }
}

</script>