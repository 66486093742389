<template>
    <base-detail
      v-bind:title="$filters.localize('MessageDetail.caption')"
      v-bind:rootId="rootId"
      v-bind:rootColumn="rootColumn"
      v-bind:schemaName="'message'"
      v-bind:headers="headers"
      v-on:main-save="main-save"
      v-on:on-parrent-load="on-parrent-load"
    >
    </base-detail>
</template>

<script>
import BaseDetail from '@/components/Sections/BaseDetail.vue';
export default {
    name: "MessageDetail",
    emits: ["on-parrent-load", "main-save"],
    components: { BaseDetail },
    props: {
        rootId: { type: String, require: true },
        rootColumn: { type: String, require: true },
    },
    methods: {},
    data() {
        return {
            headers: [
                { field: "createdOn", name: this.$filters.localize('main.createdOn'), sort: true, isDate: true },
                { field: "text", name: this.$filters.localize('main.text'), sort: true }
            ]
        };
    }
}
</script>