<template>
  <vSelect
    :options="cities"
    v-model="city"
    @search="search"
    :reduce="city => city"
    :required="required"
  />
</template>

<script>
import vSelect from "vue-select";
export default {
  name: "select-city",
  emits: ['update', "required"],
  props: { entity: Object },
  components: { vSelect },
  data() {
    return {
      cities: []
    };
  },
  computed: {
    city: {
      get() {
        return { code: this.entity.city, label: this.entity.cityName };
      },
      set(value) {
        this.$emit("update", value);
      }
    }
  },
  methods: {
    async search(name) {
      if (name && name != '' && name != null && name != undefined) {
        const result = await this.$store.dispatch('getAPI', { controller: "address", method: "cities/" + name });
        this.cities = result.data.data[0].addresses.map(x => this.map(x));
      }
    },
    map(data) {
      return { code: data.deliveryCity, label: data.present };
    },
  }
};
</script>