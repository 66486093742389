<template>
  <div>
    <div>
      <div
        v-if="!isCustomIconPanel"
        class="text-left pl-3"
      >
        <img
          src="@/assets/logo-h.png"
          style="max-width:200px; max-height:100px;"
        >
      </div>
      <slot
        v-else
        name="icon-panel"
      ></slot>
    </div>
    <div
      class="row m-0 p-0 pr-3"
      v-if="!isHideButton"
    >
      <div class="col text-left">
        <div class="btn-group">
          <button
            type="button"
            class="btn btn-sm btn-outline-success"
            v-on:click="router(uuid, editPage)"
          >{{ $filters.localize("button.add") }}</button>
          <slot name="buttons">
          </slot>
          <button
            type="button"
            v-if="filters.length > 0"
            class="btn btn-sm btn-outline-secondary"
            v-on:click="clearFilters"
          >{{ $filters.localize("button.filter.clear") }}</button>
        </div>
      </div>
      <div class="col">
        <div class="row">
          <slot
            class="col"
            name="count"
            :data="pagination"
          ></slot>
          <div class="col">{{ $filters.localize("main.countTitle") }}: {{ pagination.count }}</div>
        </div>
      </div>
    </div>
    <form
      class="input-group input-group-md ml-3 pr-5"
      v-if="fields.length > 0"
      @submit.prevent="addFilter(filter)"
    >
      <select
        v-model="filter.field"
        class="form-control"
      >
        <option
          v-for="option in fields"
          :key="option.field"
          :value="option.field"
        >
          {{ option.name }}
        </option>
      </select>
      <select
        :disabled="!filter.field || filter.field.length == 0"
        v-model="filter.type"
        class="form-control"
      >
        <option
          v-for="option in typesFilterfilter(filter)"
          :key="option.value"
          :value="option.value"
        >
          {{ option.name }}
        </option>
      </select>
      <input
        v-if="currentInputType(filter) !== 'lookup'"
        :type="currentInputType(filter)"
        class="form-control text-center"
        v-model="filter.value"
        required
      />
      <select
        v-else
        class="form-control"
        v-model="filter.value"
        required
      >
        <option
          v-for="option in fields.find(x => x.field === filter.field).lookup"
          :key="option.id"
          :value="option.id"
        >
          {{ option.name }}
        </option>
      </select>

      <button
        type="submit"
        :disabled="!(!!filter.value && !!filter.field && !!filter.type) && currentInputType(filter) != 'checkbox'"
        class="btn btn-md btn-outline-secondary"
      >
        {{ $filters.localize("main.submitFilter") }}
      </button>
    </form>
    <div
      v-if="filters.length > 0"
      class="row m-0 p-0 pr-3"
    >
      <div
        v-for="(filter, index) in filters"
        :key="index"
        class="col-sm"
      >
        <div class="input-group input-group-md">
          <label
            class="form-control text-left"
            @click.prevent="removeFilter(index)"
          >
            {{ getFilterString(filter) }}
          </label>
          <select
            v-model="filter.type"
            class="form-control"
          >
            <option
              v-for="option in typesFilterfilter(filter)"
              :key="option.value"
              :value="option.value"
            >
              {{ option.name }}
            </option>
          </select>
          <input
            v-if="currentInputType(filter) !== 'lookup'"
            :type="currentInputType(filter)"
            class="form-control text-center"
            v-model="filter.value"
            required
          />
          <select
            v-else
            class="form-control"
            v-model="filter.value"
            required
          >
            <option
              v-for="option in fields.find(x => x.field === filter.field).lookup"
              :key="option.id"
              :value="option.id"
            >
              {{ option.name }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row m-0">
      <div class="col">
        <div
          class="rounded bg-white mr-3"
          :class="filters.length > 0 ? 'table-container-sm' : fields.length > 0 ? 'table-container-xs' : 'table-container'"
        >
          <table class="table table-hover rounded">
            <thead>
              <tr class="rounded shadow">
                <th v-if="isCustomRow"></th>
                <th>#</th>
                <th
                  class="text-center"
                  v-for="header of headers"
                  v-bind:colspan="header.colspan ? header.colspan : ''"
                  v-bind:key="header.field"
                >
                  <p class="d-inline">{{ header.name }} &nbsp;</p>
                  <fas-sort
                    class="d-inline"
                    v-if="header.sort"
                    @click="sort(header.field)"
                    v-bind:currentSort="currentSort"
                    v-bind:currentSortDir="currentSortDir"
                    v-bind:field="header.field"
                  />
                </th>
              </tr>
            </thead>
            <slot
              name="body"
              v-if="isCustomRow"
              :data="rowsData"
              :headers="headers"
              :getRowValue="getRowValue"
              :router="router"
              :routerOpen="routerOpen"
              :pageCount="(currentPage - 1) * pageSize + 1"
            >
            </slot>
            <tbody v-else>
              <tr
                v-for="(row, index) of rowsData"
                v-bind:key="row.id"
              >
                <td>{{ (currentPage - 1) * pageSize + index + 1 }}</td>
                <td
                  class="text-wrap"
                  v-bind:colspan="header.colspan ? header.colspan : ''"
                  v-bind:class="header.class ? header.class : ''"
                  v-for="header of headers"
                  v-bind:key="row.id + header.field"
                  v-on:click.middle.prevent="routerOpen(getRowValue(row, header.router), header.router?.name)"
                  v-on:click.prevent="router(getRowValue(row, header.router), header.router?.name)"
                >
                  {{ getRowValue(row, header) }}
                </td>
              </tr>
            </tbody>
          </table>

          <div
            v-if="this.pagination.entities?.length == 0"
            class="text-center"
          >
            {{ $filters.localize("main.notdata") }}
          </div>

          <div
            v-if="loading"
            class="loader-overlay"
          >
            <div
              class="spinner-border text-primary"
              role="status"
            >
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center row w-100">
          <nav
            class="col"
            v-if="pagination.entities?.length != 0"
          >
            <ul class="pagination">
              <li
                class="page-item"
                v-if="currentPage - 1 > 0"
              >
                <a
                  class="page-link"
                  v-on:click="backPage"
                  aria-label="Предидущая"
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li
                class="page-item"
                v-if="currentPage - 1 > 0"
              ><a
                  class="page-link"
                  v-on:clilck="backPage"
                >{{ currentPage - 1 }}</a></li>
              <li class="page-item"><a
                  class="page-link"
                  disable
                >{{ currentPage }}</a></li>
              <li
                class="page-item"
                v-if="pagination.totalPages > currentPage - 1"
              ><a
                  class="page-link"
                  v-on:click="nextPage"
                >{{ currentPage + 1 }}</a></li>
              <li
                class="page-item"
                v-if="pagination.totalPages > currentPage - 1"
              >
                <a
                  class="page-link"
                  v-on:click="nextPage"
                  aria-label="Следующая"
                >
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
          <div class="col text-right">{{ (pagination.packageSize * (currentPage - 1) + 1) ?? 0 }} - {{
            (pagination.packageSize *
              currentPage - (pagination.packageSize - pagination.entities.length)) ?? 9 }} із {{ pagination.count ?? 0 }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import fasSort from '@/components/Sections/FasSort.vue';
import table from '@/mixins/table.js';
export default {
  name: "TSection",
  mixins: [table],
  props: ["isHideButton", "isCustomRow", "isCustomIconPanel"],
  components: {
    fasSort
  },
  data() {
    return {
      uuid: uuidv4(),
      showFilter: false,
    }
  },
  computed: {
    editPage: {
      get() {
        return this.schemaName.charAt(0).toUpperCase() + this.schemaName.slice(1) + 'Page';
      }
    },
    pageSize: {
      get() {
        return this.pagination.packageSize;
      }
    }
  },
  methods: {
    typesFilterfilter(filter) {
      if (filter.field) {
        var type = this.fields.find(x => x.field == filter.field)?.type ?? "all";
        return this.filterTypes.filter(x => x.type == "all" || x.type.includes(type));
      }
      return [];
    },
    currentInputType(filter) {
      if (filter.field) {
        var type = this.fields.find(x => x.field == filter.field)?.type ?? "all";
        return type == "all" ? "text" : type;
      }
      return "text";
    }
  },
  mounted() {
    this.loadRows();
  }
}
</script>

<style scoped>
table th {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  background: #fff;
}

.table-container-sm {
  position: relative;
  overflow-y: scroll;
  max-height: calc(83.5vh);
}

.table-container-xs {
  position: relative;
  overflow-y: scroll;
  max-height: calc(84.5vh);
}

.table-container {
  position: relative;
  overflow-y: scroll;
  max-height: calc(90vh);
}

.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.7);
  /* Напівпрозорий фон */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  /* Важливо, щоб лоадер був поверх таблиці */
}
</style>