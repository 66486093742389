<template>
    <div class="pt-2 pb-2">
        <div class="mr-3">
            <router-link :to="{ name: button.path }" v-for="(button, index) in buttons" v-bind:key="index" class="d-inline mb-3 btn-sm text-center text-uppercase">
                {{ button.name }}
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "MainNavMenu",
    props:{
        buttons: {
            type: Array,
            required: true
        },
    }
}
</script>

<style scoped>
a {
  color: white;
}
a.hover {
    color: white;
}
</style>