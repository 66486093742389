import 'bootstrap/dist/css/bootstrap.css';
import { createApp } from 'vue';
import { createStore } from 'vuex';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import cookie from 'js-cookie';
import cookies from 'vue3-cookies';
import toaster from '@meforma/vue-toaster';
import localizeFilter from '@/filters/localize.filter'
//#region store
import login from './store/login';
import entities from './store/entities';
import bot from './store/bot';
import "vue-select/dist/vue-select.css";
//#endregion store
const confCookies = {
    expireTimes: "2h",
    path: "/",
    secure: true
};

const store = createStore({
    strict: true,
    modules:{ login, entities, bot },
})

let app = createApp(App);

app.config.globalProperties.$http = axios;
const token = cookie.get("Authorization")
if (token) {
    app.config.globalProperties.$http.defaults.headers.common['Authorization'] = token
}

app.config.globalProperties.$filters = {
    localize(value) {
      return localizeFilter(value);
    }
  }
app.use(toaster, { position: "top-right"});
app.use(store);
app.use(cookies, confCookies);
app.use(router).mount('#app')