<template>
  <div>
    Навіщо сюди лізти?
  </div>
</template>

<script>
export default {
    name:"LoginPage",
    mounted() {
      var token = this.$route.params.id;
      if(token !== null && token !== undefined) {
        this.$store.dispatch("botLogin", token);
        if(this.$route.params.page)
          this.$router.push({ name: this.$route.params.page});
        else this.$router.push({ path:"/bot"})
      }
    }
}
</script>