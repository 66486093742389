<template>
    <div class="p-4 bg-white rounded shadow">
        <div
          class="text-right row"
          v-on:click="isOpen = !isOpen"
        >
            <div class="col-sm text-left">{{ title }}
                <i
                  class="fas fa-sort-down"
                  v-if="!isOpen"
                >&nbsp;</i>
                <i
                  class="fas fa-sort-up"
                  v-else-if="isOpen"
                >&nbsp;</i>
            </div>
            <div class="col-sm">
                {{ $filters.localize("main.countTitle") }}: {{ this.rows.length }}
            </div>
        </div>
        <div v-if="isOpen">
            <slot
              v-if="entity"
              class="mb-3"
              :entity="entity"
              :save="save"
              :add="addRow"
              :rows="rows"
              :loadRows="loadRows"
            ></slot>
            <div
              class="table-responsive bg-white rounded shadow flow-enabled"
              style="max-height: 250px;"
            >
                <table class="table table-sm table-hover sortable">
                    <thead>
                        <tr>
                            <th
                              class="text-center"
                              v-for="header of headers"
                              v-bind:key="header.field"
                            >
                                <p class="d-inline">{{ header.name }} &nbsp;</p>
                                <fas-sort
                                  class="d-inline"
                                  v-if="header.sort"
                                  @click="sort(header.field)"
                                  v-bind:currentSort="currentSort"
                                  v-bind:currentSortDir="currentSortDir"
                                  v-bind:field="header.field"
                                />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                          v-for="(row) of rowsData"
                          v-bind:key="row.id"
                        >
                            <td
                              class="text-center vertical-center"
                              v-bind:class="header.class ? header.class : ''"
                              v-for="header of headers"
                              v-bind:key="header.field + row.id"
                              v-on:click.prevent="router(getRowValue(row, header.router), header.router?.name)"
                            >

                                <button
                                  v-if="header.action"
                                  type="button"
                                  class="btn btn-sm btn-danger"
                                  v-on:click="remove(row.id)"
                                >X</button>
                                <input
                                  :id="row.id"
                                  @blur="save(this.rowsData.filter(x => x.id === row.id)[0])"
                                  class="form-control text-center vertical-center"
                                  v-else-if="header.input && header.input.type != 'checkbox'"
                                  :type="header.input.type"
                                  :disabled="!header.enabeled"
                                  v-model="row[header.field]"
                                >

                                <input
                                  :id="row.id"
                                  @blur="save(this.rowsData.filter(x => x.id === row.id)[0])"
                                  class="text-center vertical-center"
                                  v-else-if="header.input && header.input.type == 'checkbox'"
                                  :type="header.input.type"
                                  :disabled="true"
                                  v-model="row[header.field]"
                                >

                                <select
                                  :id="row.id"
                                  @blur="save(this.rowsData.filter(x => x.id === row.id)[0])"
                                  v-else-if="header.lookup"
                                  :disabled="!header.enabeled"
                                  class="form-control vertical-center"
                                  v-model="row[header.field]"
                                >
                                    <option
                                      v-for="option in lookups[header.lookup]"
                                      v-bind:key="option.id"
                                      :value="option.id"
                                    >
                                        {{ option.name }}
                                    </option>
                                </select>

                                <img
                                  v-else-if="header.image"
                                  v-bind:src="getRowValue(row, header) ?? require('@/assets/not-pictured-large.png')"
                                >

                                <p
                                  :id="row.id"
                                  cla
                                  v-else
                                > {{ getRowValue(row, header) }}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import FasSort from '@/components/Sections/FasSort.vue';
import tabledetail from '@/mixins/tabledetail.js';
export default {
    name: "BaseDetail",
    mixins: [tabledetail],
    emits: ["on-parrent-load", "main-save"],
    components: {
        FasSort,
    },
    computed: {
        isOpen: {
            get() {
                if (this.disableOpenClose)
                    return this.disableOpenClose;
                return this.baseIsOpen;
            },
            set(value) {
                this.baseIsOpen = value;
            }
        }
    },
    data() {
        return {
            baseIsOpen: true,
        }
    },
    props: {
        title: String,
        entity: Object,
        lookups: Object,
        headers: { type: Array, require: true },
        rootId: { type: String, require: true },
        rootColumn: { type: String, require: true },
        removeButton: Boolean,
        disableOpenClose: Boolean
    },
    methods: {
        addRow(row) {
            this.rows.unshift(row);
            this.$emit("on-parrent-load");
        },
        remove(id) {
            this.$store.dispatch("remove", { table: this.schemaName, body: { id: id } })
                .then(() => {
                    this.$toast.success("Информация успешно удалена");
                    this.rows = this.rows.filter(x => x.id !== id);
                    this.$emit("on-parrent-load");
                })
                .catch(error => this.$toast.error(error.response.data.message));
        },
        save(data) {

            this.$store.dispatch(data.id ? "update" : "add",
                {
                    table: this.schemaName,
                    body: this.getRequest(data)
                })
                .then(response => {
                    if (!data.id) {
                        this.rows.unshift(response.data.data);
                    }

                    this.$toast.success("Информацию: успешно обновлено");
                    this.$emit("on-parrent-load");
                })
                .catch(error => {
                    this.$toast.error(error.response.data.message);
                    console.log(error);
                });
        },
        getRequest(data) {
            let keys = Object.keys(data).filter(x => x.includes("Id")).map(x => x.replace("Id", ""));
            let obj = data;
            keys.forEach(x => obj[x] = null);
            return JSON.parse(JSON.stringify(obj));
        },
        loadRows() {
            this.$store.dispatch('getFilter', { table: this.schemaName, body: { id: this.rootId, rootColumn: this.rootColumn } })
                .then((response) => {
                    this.rows = response.data.data;
                });
        },
    },
    mounted() {
        this.isNotPages = true;
        this.loadRows();
    }
}
</script>

<style scoped>
table th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 10;
    background: #fff;
}

img {
    max-height: 100px;
    max-width: 150px;
}

.vertical-center {
    display: table-cell;
    vertical-align: middle;
}
</style>