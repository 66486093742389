<template>
    <div class="rounded shadow bg-white m-2 mr-3 flow-enabled">
        <div v-if="merge.length > 1"><button
              :disabled="!main"
              class="btn ml-1 mb-3 btn-sm btn-warning"
              v-on:click="fetch"
            >Об'єднати обрані резерви</button></div>
        <table class="table table-hover rounded shadow">
            <thead>
                <tr class="rounded shadow">
                    <th></th>
                    <th
                      class="text-center"
                      v-for="header of headers"
                      v-bind:key="header.field"
                    >
                        <p class="d-inline">{{ header.name }} &nbsp;</p>
                    </th>
                    <th></th>
                </tr>
            </thead>
            <tbody
              v-for="(row, index) of rows"
              v-bind:key="index"
            >
                <tr>
                    <td
                      v-on:click.prevent="getProducts(row.id)"
                      v-if="products.filter(x => x?.activityId == row.id).length == 0"
                    >+</td>
                    <td
                      v-on:click.prevent="removeProducts(row.id)"
                      v-else
                    >-</td>
                    <td
                      class="text-center"
                      v-bind:class="header.class ? header.class : ''"
                      v-for="header of headers"
                      v-bind:key="index + header.field"
                      v-on:click="router(row.id, 'OrderBotPage')"
                    >
                        {{ getRowValue(row, header) }}
                    </td>
                    <td><button
                          class="btn btn-sm btn-danger"
                          v-on:click.prevent="cancel(row.number, row.contact.code)"
                        >{{ $filters.localize("button.cancel") }}</button></td>
                    <td>
                        <button
                          v-if="merge.filter(x => x == row.id).length == 0"
                          class="btn btn-sm btn-info"
                          v-on:click.prevent="addMerge(row.id)"
                        >Об'єднати</button>
                        <button
                          v-else
                          class="btn btn-sm btn-warning"
                          v-on:click.prevent="removeMerge(row.id)"
                        >Не об'єднувати</button>
                        <button
                          v-if="merge.filter(x => x == row.id).length == 1 && main == row.id"
                          class="btn ml-1 btn-sm btn-success"
                          v-on:click="resetMain(row.id)"
                        >Основний</button>
                        <button
                          v-if="merge.filter(x => x == row.id).length == 1 && main != row.id"
                          class="btn ml-1 btn-sm btn-warning"
                          v-on:click="resetMain(row.id)"
                        >Буде скасований</button>
                    </td>
                </tr>
                <td
                  :colspan="headers.length + 1"
                  v-if="products.filter(x => x?.activityId == row.id).length > 0"
                >
                    <table class="col">
                        <thead>
                            <tr class="rounded shadow">
                                <th class="text-center">
                                    Код продукту
                                </th>
                                <th class="text-center">
                                    Розмір
                                </th>
                            </tr>
                        </thead>
                        <tbody
                          v-for="row of products.filter(x => x?.activityId == row.id)"
                          v-bind:key="row.id"
                        >
                            <tr>
                                <td>
                                    {{ row.product?.code }}
                                </td>
                                <td>
                                    {{ row.productRemain?.character?.name }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tbody>
        </table>
        <div
          v-if="rows.length === 0"
          class="text-center"
        >У вас відсутні активні резерви</div>
    </div>
</template>

<script>
import rows from '@/mixins/rows.js';
export default {
    name: "Reservations",
    mixins: [rows],
    data() {
        return {
            headers: [{ field: "number", name: "Номер" }, { field: "reservationExpired", name: "Час згорання", isDate: true }],
            rows: [],
            products: [],
            merge: [],
            main: null,
        }
    },
    methods:
    {
        loadRows() {
            this.$http.get(window.location.origin + "/api/v1/order/reservations")
                .then((response) => {
                    this.rows = response.data.data;
                });
        },
        getProducts(rootId) {
            this.$store.dispatch('getFilter', { table: "orderproduct", body: { id: rootId, rootColumn: "ActivityId" } })
                .then((response) => {
                    this.products = [...this.products, ...response.data.data];
                });
        },
        removeProducts(rootId) {
            this.products = this.products.filter(x => x?.activityId !== rootId);
        },
        cancel(number, contact) {
            this.$store.dispatch('postAPI', { controller: 'order', method: 'cancel/byNumber', body: { number, contact } })
                .then(_ => {
                    this.$toast.success("Резерв снят");
                    this.loadRows();
                }).catch(error => {
                    this.$toast.error(error.response.data.message)
                });
        },
        addMerge(id) {
            this.merge.push(id);
            if (!this.main) {
                this.resetMain(id);
            }
        },

        removeMerge(id) {
            this.merge = this.merge.filter(x => x !== id);

            if (this.main == id && this.merge.length > 0) {
                this.main = this.merge[0];
            }
        },

        resetMain(id) {
            if (this.main == id) {
                if (this.merge.length > 0) {
                    this.main = this.merge[0];
                }
                else this.main = null;
            }
            else {
                this.main = id;
            }
        },
        fetch() {
            var main = this.rows.find(x => x.id == this.main);
            this.$store.dispatch('postAPI',
                {
                    controller: 'order',
                    method: 'merge',
                    body: {
                        main: this.main,
                        orders: this.merge.filter(x => x != this.main)
                    }
                }).then(_ => {
                    this.$toast.success("Резерви об'єднані успішно до основного резерву №" + main.number);
                    this.$toast.info("Решта обраних резервів - скасовані.")
                    this.loadRows();
                    this.main = null;
                    this.merge = [];
                }).catch(_ => {
                    this.$toast.error("Резерви не можуть бути об'єднані між собою");
                });
        }
    },
    mounted() {
        this.loadRows();
    }
}
</script>