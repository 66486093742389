<template>
    <base-detail 
        v-bind:title="title"
        v-bind:rootId="rootId" 
        v-bind:rootColumn="rootColumn"
        v-bind:schemaName="'transaction'"
        v-bind:headers="headers"
        v-bind:entity="entity"
        v-bind:lookups="lookups"
        v-on:main-save="main-save"
        v-on:on-parrent-load="on-parrent-load">
        <template v-slot="{entity, loadRows}">
            <form 
                v-on:submit.prevent="save(entity, loadRows)" 
                class="input-group mb-3">
                <select :disabled="entity.contactId" class="form-control" v-model="entity.contactId" required>
                    <option value="" disabled selected>Контакт</option>
                    <option v-for="option in contacts"
                        v-bind:key="option.id" 
                        :value="option.id">
                            {{ option.fullName }}
                    </option>
                </select>
                <input class="form-control" :placeholder="$filters.localize('main.countTitle')" type="numeric" min="0" v-model="entity.amount" required>
                <input class="form-control" type="text" v-model="entity.reason" list="reason" required>
                <datalist id="reason">
                    <option v-for="reason in lookups.reason" :value="reason" :key="reason">{{ reason }}</option>
                </datalist>
                <div class="input-group-append">
                    <button :disabled="!enabled || entity.amount == 0"
                        class="btn btn-outline-secondary"
                        type="submit">{{ entity.amount > 0 ? 'Нарахувати' : 'Списати' }}</button>
                </div>
            </form>
        </template>
    </base-detail>
</template>

<script>
import BaseDetail from '@/components/Sections/BaseDetail.vue';
export default {
    name: "TransactionsDetail",
    components:{ BaseDetail },
    emits:["on-parrent-load","main-save"],
    props:{
        rootId: { type:String, require:true },
        rootColumn: { type:String, require:true },
        contactId: { type:String, require:true },
        enabled: { type: Boolean}
    },
    methods:{
        save(data, loadRows){
            this.$store.dispatch("add", 
                { 
                    table: 'transaction', 
                    body: JSON.parse(JSON.stringify(data))
                })
            .then(() => { 
                if(!data.id){
                    loadRows.call();
                    this.getBalance();
                    this.loadLookups();
                }
                this.$toast.success("Інформацію: успішно оновлено.");})
            .catch(error => {
                this.$toast.error(error.response.data.message);
                console.log(error);
            });  
        },

        loadLookups() {
            if(this.entity.contactId){
                this.$store.dispatch('getFilter', { table: "contact", body:{ id: this.entity.contactId, rootColumn: "Id" }})
                .then((response) => {
                    this.lookups.contacts = response.data.data;
                });
            }
            else {
                this.$store.dispatch('section', { table: "contact" })
                .then((response) => {
                    this.lookups.contacts = response.data.data;
                });
            }
        },
        getBalance(){
            if(this.entity.contactId){
                this.$store.dispatch("get", { table:"transaction", method: 'balance?contactId='+this.entity.contactId })
                .then(response => {
                    this.balance = response.data.data;
                })
            }
        }
    },
    computed: {
        title: {
            get() {
                return "Баланс: " + this.balance;
            }
        },
        contacts: {
            get() {
                if(this.entity.contactId)
                    return [...this.lookups.contacts];
                else    
                    return [...this.lookups.contacts].filter(x => x.id == this.entity.contactId)
            }
        }
    },
    watch: { 
        contactId: function(newVal) {
            this.entity.contactId = newVal
            this.getBalance();
        }
    },
    mounted(){
        this.entity.contactId = this.contactId;
        if(this.contactId !== this.rootId)
            this.entity.activityId = this.rootId;
        this.loadLookups();
        this.getBalance();
    },
    data(){
        return {
            lookups:{
                contacts:[],
						reason: ["Оплата товару", "Скасування замовлення", "Виплата за накладеним платежем", "Повернення товару"]
            },
            balance: 0,
            entity: {
                amount:0,
                activityId: null,
                contactId: null,
                reason:""
            },
            headers: [
                { field:"createdOn", name:this.$filters.localize('main.createdOn'), sort: true, isDate: true},
                { field:"createdBy.fullName", name:"Контакт", router: { id:"contactId", name:"ContactPage" } },
                { field:"activity.number", name:"Замовлення", sort:true, router: { id:"activityId", name:"OrderPage" }},
                { field:"type.name", name:"Операція", sort: true },
                { field:"amount", name:this.$filters.localize('main.countTitle'), sort:true },
                { field:"reason", name:"Причина" }
            ]
        };
    }
}
</script>