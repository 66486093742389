<template>
    <chart
      type="bar"
      id="OrderChart"
      :categories="categories"
      :series="series"
    ></chart>
</template>

<script>
import Chart from "@/components/Charts/Chart.vue";
export default {
  name:"OrderChart",
  components: { Chart },
  computed:{
      categories:{
          get(){
              return [...this.status.map(x => x.name)]
          }
      },
      series:{
        get() {
            let allTime = [];
            let toDay = [];
            let mounth = [];
            this.categories.forEach(item => {
                let result = this.data.filter(o => o.status.name == item);
                allTime.push(result.length);
                
                result = this.data.filter(o => o.status.name == item && this.filterToday(o.createdOn));
                toDay.push(result.length);
                
                result = this.data.filter(o => o.status.name == item && this.filterMouth(o.createdOn));
                mounth.push(result.length);
            });
            return [
                { name: "За весь час", data: allTime },
                { name: "За місяць", data: mounth },
                { name: "За сьогодні", data: toDay }];
        }
    }
  },
  methods:{
      filterToday(createdOn){
          let date = new Date(createdOn);
          let now = new Date(Date.now());
          return date.getFullYear() == now.getFullYear()
            && date.getMonth() == now.getMonth()
            && date.getDate() == now.getDate();
      },
      filterMouth(createdOn){
          let date = new Date(createdOn);
          let now = new Date(Date.now());
          return date.getFullYear() == now.getFullYear()
            && date.getMonth() == now.getMonth();
      },
      loadCategory(){
        this.$store.dispatch('section', { table: "orderstatus"})
            .then((response) => {
            this.status = response.data.data;
        });
      },
      loadData(){
        this.$store.dispatch('section', { table: "order"})
            .then((response) => {
            this.data = response.data.data;
        });
      }
  },
  mounted(){
      this.loadCategory();
      this.loadData();
  },
  data() {
    return {
        status:[],
        data:[]
    };
  },
};
</script>