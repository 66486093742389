import rows from '@/mixins/rows.js';
export default {
    mixins:[rows],
    props: { 
        headers: Array,
        fields: Array,
        schemaName: { type:String, required: true},
        defaultSort: String,
    },
    data(){
        return {
            currentSort:"",
            isNotPages: false,
            currentSortType: "asc",
            currentPage: 1,
            sizePage: 15,
            filter: {
                type: "",
                value: null,
                field: ""
            },
            filterTypes: [
                { name: "Дорівнює", value: "equal", char:"=", type: "all"},
                { name: "Не дорівнює", value: "notequal", char: "<>", type: "all" },                
                { name: "Більше", value: "more", char: ">", type: "number, date" },
                { name: "Меньше", value: "less", char:"<", type: "number, date" },
                { name: "Включає", value: "contains", char:"включає", type: "string" },
                { name: "Виключає", value: "notcontains", char:"виключає", type: "string" }
            ],
            rows: [],
            filters:[],
        }
    },
    computed: {
        currentPageSize:{
            get(){
                return this.sizePage * this.currentPage;   
            }
        },
        oldPageSize:{
            get(){
                return this.sizePage * (this.currentPage - 1);
            }
        },
        currentSortDir: {
            get() {
                return this.currentSortType;
            },
            set(value) {
                this.currentSortType = value;
            }
        },
        rowsData: {
            get() {
                if(!this.isNotPages){
                    return this.rowsSortData.filter((x, index) => {
                        if(index < this.currentPageSize)
                            if(index >= this.oldPageSize)
                            return x;
                    });
                } 
                else 
                return this.rowsSortData
            }
        },        
        rowsSortData: {
            get() {
                return [...this.rowsFilterData].sort((a,b) => {
                    let modifier = 1;
                    if(this.currentSortDir === 'desc') modifier = -1;
                    if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                });
            }
        },
        rowsFilterData: {
            get() {
                var data = [...this.rows];
                this.filters?.forEach(filter => {
                    data = data.filter(x => this.applyFilter(x, filter));
                });                
                return data;
            }
        }
    },
    methods: {
        nextPage(){
            if(this.rowsSortData.length / this.sizePage > this.currentPage)
                this.currentPage += 1;
        },
        backPage(){
            if(this.currentPage > 1)
                this.currentPage -= 1;
        },
        getFilterString(filter) {
            return this.fields.find(x => x.field == filter.field)?.name
        },
        applyFilter(item, filter) {
            var value = this.getRowValue(item, filter)?.toString();
            var type = this.fields.find(x => x.field == filter.field)?.type;
            if (filter?.type === "equal")
            {
                if (type == "date") {
                    return this.formatDate(value) == new Date(filter.value);
                } else if (type == "number") {
                    return Number(value) == Number(filter.value);
                } else if(type == "checkbox"){
                    return Boolean(value) == filter.value;
                }
                return value == filter.value;
            }
            else if (filter?.type === "notequal")
            {
                if (type == "date") {
                    return this.formatDate(value) != new Date(filter.value);
                } else if (type == "number") {
                    return Number(value) != Number(filter.value);
                } else if(type == "checkbox"){
                    return Boolean(value) != filter.value;
                }
                return value != filter.value;
            }
            else if (filter?.type === "contains")
                return value?.includes(filter.value);
            else if (filter?.type === "notcontains")
                return !value?.includes(filter.value);
            else if (filter?.type === "more")
            {
                if (type == "date") {
                    let dateFilter = new Date(filter.value);
                    return this.formatDate(value) > dateFilter;
                } else if (type == "number") {
                    return Number(value) > Number(filter.value);
                }
                return value > filter.value;
            }
            else if (filter?.type === "less")
            {
                if (type == "date") {                    
                    let dateFilter = new Date(filter.value);
                    return this.formatDate(value) < dateFilter;
                } else if (type == "number") {
                    return Number(value) < Number(filter.value);
                }
                return value < filter.value;
            }
        },
        formatDate(date) {
            var dateField = new Date(date);
            return new Date(dateField.getFullYear(), dateField.getMonth(), dateField.getDate());
        },
        addFilter(filter) {
            this.filters.push(filter);
            localStorage.setItem(this.schemaName, JSON.stringify(this.filters));
            this.filter = {
                type: "",
                value: null,
                field: ""
            };
        },
        removeFilter(indexFilter) {
            this.filters = this.filters.filter((_, index) => index !== indexFilter);
            localStorage.setItem(this.schemaName, JSON.stringify(this.filters));
        },
        clearFilters() {
            this.filters = [];
            localStorage.removeItem(this.schemaName);
        },
        sort(field) {
            if(field === this.currentSort) 
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
            this.currentSort = field;
        },
        loadRows() {
            this.$store.dispatch('section', { table: this.schemaName, body: {}})
                .then((response) => {
                    this.rows =  response.data.data;
            });
        },
    },
    mounted() {
        this.currentSort = this.headers[0].field;
        if(this.defaultSort)
            this.currentSortDir = this.defaultSort;
        var result = localStorage.getItem(this.schemaName);
        if(result)
            this.filters = JSON.parse(result);
    }
}