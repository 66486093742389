<template>
<div class="center-block ">
    <div class="container-fluid p-0">
        <table id="catalog" class="table table-bordered table-responsive">
        <thead>
            <tr>
                <th colspan="14" style="height: 65px;">
                    <div class="btn-group" role="group">
                        <button type="button" class="btn btn-secondary" v-on:click.prevent="changeCategory('1bf61851-347c-4d0d-a62e-d488dce9c0da')">Мужская</button>
                        <button type="button" class="btn btn-secondary" v-on:click.prevent="changeCategory('2bf61851-347c-4d0d-a62e-d488dce9c0da')">Женская</button>
                    </div>
                </th>
            </tr>
            <tr>
                <th colspan="2" class="bg-secondary" style="width: calc(219px); height: 78px;">
                    <input type="number" placeholder="введите код" autocomplete="off" v-model="filter" class="form-control mt-2" style="font-size: 21px;">
                </th>
                <th class="bg-secondary" style="width: 191px;">
                    Описание товара
                </th>
                <th class="bg-secondary" style="width: 124px;">
                    Размерность
                </th>
                <th class="bg-secondary" style="width: 115;">
                    Комментарий
                </th>
                <th class="bg-secondary" style="width: 65px;">
                    Цена розн
                </th>
                <th class="bg-secondary" style="width: 65px;">
                    Цена опт
                </th>
                <th class="bg-secondary" colspan="7">
                    Размеры в наличии
                </th>
            </tr>
        </thead>
        <tbody>
            <tr style="height: 164px;" v-for="row in rowsFilter" :key="row.code">
                <td class="text-center bg-secondary" style="width: 55px;">
                    <b style="font-size: 18px; font-family: Impact !important;">
                    <label>{{ row.code }}</label>
                    </b>
                </td>
                <td class="p-0 align-middle text-center" style="width: 164px;">
                    <img :data-src="row.photo" :src="row.photo" lazy="loaded" style="width:164px;">
                </td>
                <td class="text-center" style="width: 191px; font-size: 14px; line-height: 16px; padding: 10px 0px;">
                    <div style="width: 191px; white-space: pre-line;"><b>{{ row.description }}</b></div>
                </td>
                <td class="text-center" style="width: 124px; font-size: 14px; line-height: 16px; padding: 10px 0px;">
                    <div class="pre-wrap" style="width: 124px; white-space: pre-line;">{{ row.dimensionalGrid }}</div>
                </td>
                <td class="text-center" style="width: 124px; font-size: 14px; line-height: 16px; padding: 10px 0px;">
                    <div style="width: 124px; white-space: pre-line;">
                        {{ row.comment }}
                    </div>
                </td>
                <td class="text-center spec-color" style="width: 65px;">
                    <b style="font-size: 20px;">
                        {{ row.price }}    
                    </b>
                </td>
                <td class="text-center spec-color" style="width: 65px;">
                    <b style="font-size: 20px;">
                        {{ row.wholeSalePrice }}    
                    </b>
                </td>

                <td class="text-center p-0" style="width: 80px;">
                    <div class="position-relative" style="overflow-wrap: break-word; width: 80px;"></div> 
                        <div class="spec-color" style="overflow-wrap: break-word;">
                        <b style="white-space: pre-line;">
                            {{ row.size1 }}
                        </b>
                    </div>
                </td>
                <td class="text-center p-0" style="width: 80px;">
                    <div class="position-relative" style="overflow-wrap: break-word; width: 80px;"></div> 
                        <div class="spec-color" style="overflow-wrap: break-word;">
                        <b style="white-space: pre-line;">
                            {{ row.size2 }}
                        </b>
                    </div>
                </td>
                <td class="text-center p-0" style="width: 80px;">
                    <div class="position-relative" style="overflow-wrap: break-word; width: 80px;"></div> 
                        <div class="spec-color" style="overflow-wrap: break-word;">
                        <b style="white-space: pre-line;">
                            {{ row.size3 }}
                        </b>
                    </div>
                </td>
                <td class="text-center p-0" style="width: 80px;">
                    <div class="position-relative" style="overflow-wrap: break-word; width: 80px;"></div> 
                        <div class="spec-color" style="overflow-wrap: break-word;">
                        <b style="white-space: pre-line;">
                            {{ row.size4 }}
                        </b>
                    </div>
                </td>
                <td class="text-center p-0" style="width: 80px;">
                    <div class="position-relative" style="overflow-wrap: break-word; width: 80px;"></div> 
                        <div class="spec-color" style="overflow-wrap: break-word;">
                        <b style="white-space: pre-line;">
                            {{ row.size5 }}
                        </b>
                    </div>
                </td>
                <td class="text-center p-0" style="width: 80px;">
                    <div class="position-relative" style="overflow-wrap: break-word; width: 80px;"></div> 
                        <div class="spec-color" style="overflow-wrap: break-word;">
                        <b style="white-space: pre-line;">
                            {{ row.size6 }}
                        </b>
                    </div>
                </td>
                <td class="text-center p-0" style="width: 80px;">
                    <div class="position-relative" style="overflow-wrap: break-word; width: 80px;"></div> 
                        <div class="spec-color" style="overflow-wrap: break-word;">
                        <b style="white-space: pre-line;">
                            {{ row.size7 }}
                        </b>
                    </div>
                </td>
            </tr>
        </tbody>
        </table>
    </div>
</div>
</template>

<script>
    export default {
        name: 'Catalog',
        computed:{
            rowsFilter:{
                get(){
                    if(this.filter)
                        return this.rows.filter(x => x.code == this.filter);
                    return this.rows;
                }
            }
        },
        data() {
            return {
                url: window.location.origin + '/api/v1/catalog',
                rows:[],
                category: '2bf61851-347c-4d0d-a62e-d488dce9c0da',
                filter: null
            }
        },
        methods:{
            changeCategory(categoryId){
                this.category = categoryId;
                this.loadRows();
            },
            loadRows(){
                if(this.category) {
                    let url = this.url + "/by/category/" + this.category;
                    this.request(url);
                    return;
                } 
                this.request(this.url);
            },
            request(url){
                this.$http.get(url).then(response => this.rows = response.data.data);
            }
        },
        mounted(){
            this.loadRows();
        }
    }
</script>


<style scoped>
.center-block {
    display: block;
    overflow-y: scroll;
    margin-right: auto;
    margin-left: auto;
    height: 100vh;
}
::-webkit-scrollbar {
  width: 10px;
}
</style>