<template>
    <div>
        <i class="fas fa-sort-down" v-if="currentSort === field && currentSortDir === 'asc'" v-bind:class="{ active: currentSort === field }">&nbsp;</i>
        <i class="fas fa-sort-up" v-else-if="currentSort === field && currentSortDir === 'desc'" v-bind:class="{ active: currentSort === field }">&nbsp;</i>
        <i class="fas fa-sort" v-else>&nbsp;</i>
    </div>
</template>

<script>
export default {
    name:"FasSort",
    props:["currentSort", "currentSortDir", "field"]
}
</script>

<style>

</style>