import axios from 'axios';
import cookie from 'js-cookie';

export default {
  state: () => ({
    token:null,
    roleId: null,
    local:"ru-RU"
  }),
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
      cookie.set("Authorization", token, { expires: 1, secure: true });
    },

    SET_ROLE: (state, roleId) => {
      state.roleId = roleId;
      localStorage.setItem("RoleId", roleId);
    },
    
    REMOVE_TOKEN:(state) =>{
      state.token = null;
      state.roleId = null;
      cookie.remove("Authorization");
      localStorage.removeItem("RoleId");
    }
  },
  actions: {
    login({ commit }, body) {
        return axios.post(window.location.origin+'/api/login/signin', body)
          .then((response) => 
        {
            let data = response.data.data;
            axios.defaults.headers.common['Authorization'] = data.token;
            localStorage.setItem("locale", "ua-UA");
            commit("SET_TOKEN", data.token);
            commit("SET_ROLE", data.user.roleId);
          return response;
        });
    },

    createLogin(_, body) {
      return axios.post(window.location.origin+'/api/login/signup', body);
    },

    active(_, userId){
      return axios.get(window.location.origin+'/api/login/active?userId='+ userId);
    },

    disabled(_, userId) {
      return axios.get(window.location.origin+'/api/login/deactivate?userId='+ userId);
    },

    signOut({ commit }) {
      delete axios.defaults.headers.common['Authorization'];
      commit("REMOVE_TOKEN");
    }
  },
  modules: {},
  getters: {
    locale:(state) =>{
      return state.local;
    },
    isLoggedIn: (state) => {
        return !!state.token && !!cookie.get("Authorization");
    },
    isAdmin:(state) => {
      var roleId = localStorage.getItem("RoleId");
      return state.roleId == "2bf61851-347c-4d0d-a62e-d488dce9c0da" || roleId == "2bf61851-347c-4d0d-a62e-d488dce9c0da";
    },
    isBot:(state) => {
      var roleId = localStorage.getItem("RoleId");
      return state.roleId == "3bf61851-347c-4d0d-a62e-d488dce9c0da" || roleId == "3bf61851-347c-4d0d-a62e-d488dce9c0da";
    },
    getToken: (state) => {
      var token = state.token;
      if(token == null || !token)  
        token = cookie.get("Authorization");
      return token && token != "" && token != "undefined" ? token : null;  
    },
  },
};
