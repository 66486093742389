<template>
    <base-detail
      v-bind:title="$filters.localize('UserDetail.caption')"
      v-bind:rootId="rootId"
      v-bind:rootColumn="rootColumn"
      v-bind:schemaName="'user'"
      v-bind:headers="headers"
      v-bind:entity="entity"
      v-bind:lookups="lookups"
      v-on:main-save="main - save"
      v-on:on-parrent-load="on - parrent - load"
    >
        <template
          v-if="entity.contactId"
          v-slot="{ entity }"
          class="input-group"
        >
            <form
              v-on:submit.prevent="createUser"
              class="input-group mb-3"
            >
                <input
                  class="form-control"
                  :placeholder="$filters.localize('login.login')"
                  type="text"
                  v-model="entity.login"
                  required
                >
                <input
                  class="form-control"
                  :placeholder="$filters.localize('login.password')"
                  type="password"
                  v-model="entity.password"
                  required
                >
                <select
                  class="form-control"
                  v-model="entity.roleId"
                  required
                >
                    <option
                      value=""
                      disabled
                      selected
                    >Роль</option>
                    <option
                      v-for="role in lookups.roles"
                      v-bind:key="role.id"
                      :value="role.id"
                    >
                        {{ role.name }}
                    </option>
                </select>
                <div class="input-group-append">
                    <button
                      :disabled="!enabled"
                      class="btn btn-outline-secondary"
                      type="submit"
                    >{{ $filters.localize('button.add') }}</button>
                </div>
            </form>
        </template>
    </base-detail>
</template>

<script>
import BaseDetail from '@/components/Sections/BaseDetail.vue';
export default {
    name: "UserDetail",
    components: { BaseDetail },
    emits: ["on-parrent-load", "main-save"],
    props: {
        rootId: { type: String, require: true },
        rootColumn: { type: String, require: true },
        enabled: { type: Boolean }
    },
    methods: {
        createUser() {
            this.$store.dispatch('createLogin', JSON.parse(JSON.stringify(this.entity)))
                .then(() => {
                    this.entity = {
                        login: "",
                        contactId: this.rootId,
                        password: "",
                        roleId: "",
                    };
                    this.$toast.success("Користувача успішно створено");
                })
                .catch(() =>
                    this.$toast.error("Логін вже існує"));
        },
        loadLookups() {
            this.$store.dispatch('section', { table: "role" })
                .then((response) => {
                    this.lookups.roles = response.data.data;
                });
        }
    },
    mounted() {
        this.loadLookups();
    },
    data() {
        return {
            lookups: {
                roles: []
            },
            entity: {
                login: "",
                contactId: this.rootId,
                password: "",
                roleId: "",
            },
            headers: [
                { field: "userName", name: this.$filters.localize('login.login'), sort: true },
                { field: "role.name", name: "Роль", sort: true },
                { field: "isActive", name: "Активний", sort: true, input: { type: "checkbox" }, enabeled: true },
                { field: "id", name: this.$filters.localize('button.action'), action: true }
            ]
        };
    }
}
</script>