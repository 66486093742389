<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {}
}
</script>

<style>
a {
 color: black; 
}
a:hover {
  color: white;
  text-decoration: none;
}
#app {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: 10pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
html, body { margin: 0; height: 100%; overflow: hidden}

::-webkit-scrollbar {
  width: 0px;
}

.flow-enabled {
    overflow-y: scroll;
    max-height: calc(100vh - 185px);
}

.bg-header {
    background-color: #0F2641;
}

@media (max-width: 250px)
{
    html, body { margin: 0; height: 100%; overflow-y: scroll}
}
</style>
