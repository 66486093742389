<template>
    <base-detail 
        v-bind:title="$filters.localize('ProductRemainsDetail.caption')"
        v-bind:rootId="rootId" 
        v-bind:rootColumn="rootColumn"
        v-bind:schemaName="'productremains'"
        v-bind:headers="headers"
        v-bind:entity="entity"
        v-bind:lookups="lookups"
        v-bind:disableOpenClose="'true'"
        v-on:main-save="main-save"
        v-on:on-parrent-load="on-parrent-load">
        <template v-if="entity.productId" v-slot="{entity, save, rows}" class="input-group">
            <form 
                v-on:submit.prevent="save(entity)" 
                class="input-group mb-3">

                <input class="form-control" :placeholder="$filters.localize('main.countTitle')" type="number" min="0" v-model="entity.quantity" required>
                <select class="form-control" v-model="entity.characterId" required>
                    <option value="" disabled selected>{{ $filters.localize('main.size') }}</option>
                    <option v-for="option in lookups.characters.filter(item => rows.filter(x=> x.characterId == item.id) == 0)"
                        v-bind:key="option.id" 
                        :value="option.id">
                            {{ option.name }}
                    </option>
                </select>
                <div class="input-group-append">
                    <button :disabled="!enabled"
                        class="btn btn-outline-secondary" 
                        type="submit">{{ $filters.localize('button.add') }}</button>
                </div>
            </form>
        </template>
    </base-detail>
</template>

<script>
import BaseDetail from '@/components/Sections/BaseDetail.vue';
export default {
    name: "ProductRemainsDetail",
    components:{ BaseDetail },    
    emits:["on-parrent-load","main-save"],
    props:{
        rootId: { type:String, require:true },
        rootColumn: { type:String, require:true },
        enabled: { type: Boolean}
    },
    methods:{
        loadLookups() {
            this.$store.dispatch('section', { table: "productcharacter" })
            .then((response) => {
                this.lookups.characters = response.data.data;
            });
        }
    },
    mounted(){
        this.loadLookups();
    },
    data(){
        return {
            lookups:{
                characters:[]
            },
            entity:{
                characterId:"",
                productId:this.rootId,
                quantity:"",
            },
            headers: [
                { field:"characterId", name:this.$filters.localize('main.size'), sort:true, lookup:"characters", enabeled: false },
                { field:"quantity", name:this.$filters.localize('main.countTitle'), sort:true, input: { type: "numeric" }, enabeled: true}
            ]
        };
    }
}
</script>