<template>
    <base-page v-bind:schemaName="'productCategory'" v-bind:isHideRemove="true">
        <template v-slot:panel-left="{entity}">
            <div class="mb-3 text-left">
                <label class="form-label">Название</label>
                <input type="text" class="form-control" v-model="entity.name" required>
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Идентификатор таблицы</label>
                <input class="form-control" v-model="entity.sheetLink" required>
            </div>
            <div class="mb-3 text-left">
                <label class="form-label">Код</label>
                <input type="text" class="form-control" v-model="entity.code" required>
            </div>            
        </template>
    </base-page>
</template>


<script>
import BasePage from '@/components/Sections/BasePage.vue';
export default {
    name: "CaregoryPage",
    components:{ BasePage },
    data(){},
    methods:{}
}

</script>
<style scoped>
.image-size{
    max-height: 300px;
    width: 300px;
}
</style>